<template>
 <PageHeading
  :title="$t('userProfile')"
  :has-meta-data="true"
  :meta="user"
  created-at="profileCreatedOn"
 />
 <div class="m-8 overflow-hidden bg-white shadow sm:rounded-lg">
  <div class="px-4 py-6 sm:px-6">
   <h3 class="text-base font-semibold leading-7 text-gray-900">
    {{ $t("profile.userInfo") }}
   </h3>
  </div>
  <div class="border-t border-gray-100">
   <dl class="divide-y divide-gray-100">
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-900">
      {{ $t("profile.fullName") }}
     </dt>
     <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
      {{ user.name }}
     </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-900">
      {{ $t("profile.email") }}
     </dt>
     <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
      {{ user.email }}
     </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-900">
      {{ $t("profile.activeAccount") }}
     </dt>
     <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
      {{ user.activeAccount }}
     </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-900">
      {{ $t("profile.accounts") }}
     </dt>
     <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
      <div>
       <h2 class="text-xs font-medium text-gray-500">{{ $t("setActiveAccount") }}.</h2>
       <ul role="list" class="mt-3 grid grid-cols-4 gap-5 sm:grid-cols-6 sm:gap-2 lg:grid-cols-10">
        <li
         v-for="account in customerAccounts"
         :key="account.id"
         class="col-span-2 flex rounded-md shadow-sm"
        >
         <div
          class="flex flex-1 items-center justify-between rounded-md border border-gray-200 bg-white"
         >
          <div class="flex-1 px-2 py-1 text-sm">
           <a
            @click="activeAccount !== account ? checkValue(account) : ''"
            href="javascript:void(0)"
            :class="[
             activeAccount.includes(account.customerAccount)
              ? 'text-green-700 cursor-default'
              : 'cursor-pointer hover:text-gray-600 text-gray-900',
             'font-medium',
            ]"
           >
            {{ account.name }}
            <span
             v-if="activeAccount.includes(account.customerAccount)"
             class="inline-flex items-center rounded-md bg-green-50 px-1 py-px text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
             >Actif</span
            >
           </a>
           <p class="text-gray-500">{{ account.customerAccount }}</p>
          </div>
         </div>
        </li>
       </ul>
      </div>
     </dd>
    </div>
   </dl>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import PageHeading from "../components/PageHeading.vue";
const customerAccounts = JSON.parse(localStorage.getItem("customerAccounts"));
const activeAccount = localStorage.getItem("activeAccount");

export default {
 name: "Profile",
 data() {
  return { activeAccount, customerAccounts, accounts: [] };
 },
 methods: {
  checkValue(value) {
   const account = this.filteredAccounts.find(
    (account) => account.customerAccount === value.customerAccount
   );
   if (account) {
    const { customerAccount, name } = account;
    const accountData = [customerAccount, name];
    console.log(accountData);
    this.setActiveAccount(accountData);
   }
  },
  async getAccounts() {
   this.$emit("setLoading", true);

   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/contactables`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.accounts = res.data;

    this.$emit("setLoading", false);
   } catch (error) {
    this.$emit("setLoading", false);
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async setActiveAccount(event) {
   let id = event[0];
   let name = event[1];
   axios
    .put(`${this.$cookie.getCookie("API")}/api/v1/accounts/active?activeAccount=${id}`)
    .then((res) => {
     let activeAccount = name + " - " + id;
     localStorage.setItem("activeAccount", activeAccount);
     localStorage.setItem("account", id);
     localStorage.setItem("accountName", name);
     this.$store.dispatch("account", id);
     this.$store.dispatch("accountName", name);
     this.getMyPermissions(res.data.activeAccount);
    })
    .catch((error) => {
     this.errorHandling(error);
    });
  },
  async getMyPermissions(account) {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    const data = JSON.stringify(res.data);
    this.$store.dispatch("userPermissions", data);
    localStorage.setItem("userPermissions", data);
    this.$emit("setLoading", false);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   } finally {
    location.replace(location.href);
   }
  },
 },
 components: {
  PageHeading,
 },

 mounted() {
  this.getAccounts();
 },
 computed: {
  ...mapGetters(["search", "user", "account", "adminToken", "token"]),
  filteredAccounts() {
   const accounts = typeof this.accounts === "string" ? JSON.parse(this.accounts) : this.accounts;
   return this.customerAccounts.filter((account) => {
    return this.account !== account.customerAccount;
   });
  },
 },
};
</script>

<style></style>
